define("discourse/plugins/discourse-post-voting/discourse/connectors/topic-above-post-stream/add-post-voting-topic-page-class", ["exports", "truth-helpers", "discourse/helpers/body-class", "discourse/plugins/discourse-post-voting/discourse/initializers/post-voting-edits", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _truthHelpers, _bodyClass, _postVotingEdits, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AddPostVotingTopicPageClass = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.model.is_post_voting}}
      {{bodyClass
        (if
          (eq @outletArgs.model.postStream.filter ORDER_BY_ACTIVITY_FILTER)
          "post-voting-topic-sort-by-activity"
          "post-voting-topic"
        )
      }}
    {{/if}}
  
  */
  {
    "id": "Bjx3sL+T",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"model\",\"is_post_voting\"]],[[[1,\"    \"],[1,[28,[32,0],[[52,[28,[32,1],[[30,1,[\"model\",\"postStream\",\"filter\"]],[32,2]],null],\"post-voting-topic-sort-by-activity\",\"post-voting-topic\"]],null]],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-voting/discourse/connectors/topic-above-post-stream/add-post-voting-topic-page-class.js",
    "scope": () => [_bodyClass.default, _truthHelpers.eq, _postVotingEdits.ORDER_BY_ACTIVITY_FILTER],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "add-post-voting-topic-page-class:AddPostVotingTopicPageClass"));
  var _default = _exports.default = AddPostVotingTopicPageClass;
});