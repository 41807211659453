define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comments-shim", ["discourse/widgets/render-glimmer", "@ember/template-factory"], function (_renderGlimmer, _templateFactory) {
  "use strict";

  (0, _renderGlimmer.registerWidgetShim)("post-voting-comments", "div.post-voting-comments-shim", (0, _templateFactory.createTemplateFactory)(
  /*
    <PostVotingComments
      @post={{@data.post}}
      @canCreatePost={{@data.canCreatePost}}
    />
  */
  {
    "id": "PETPbwEI",
    "block": "[[[8,[39,0],null,[[\"@post\",\"@canCreatePost\"],[[30,1,[\"post\"]],[30,1,[\"canCreatePost\"]]]],null]],[\"@data\"],false,[\"post-voting-comments\"]]",
    "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comments-shim.js",
    "isStrictMode": false
  }));
});